import clsx from 'clsx';
import { formInputCss, textareaInputCss } from './Form.css';
import { Text } from './Text';

type ClassNameType = Parameters<typeof clsx>[0];

export const Label = ({ htmlFor, children }) => {
  return (
    <Text as="label" htmlFor={htmlFor}>
      {children}
    </Text>
  );
};

export const Input = ({
  id,
  name,
  className,
  ...props
}: {
  id: string;
  name: string;
  className?: ClassNameType;
}) => {
  return (
    <input
      id={id}
      name={name}
      className={clsx(formInputCss, className)}
      {...props}
    />
  );
};

export const Textarea = ({
  id,
  name,
  className,
  ...props
}: {
  id: string;
  name: string;
  className?: ClassNameType;
}) => {
  return (
    <textarea
      id={id}
      name={name}
      className={clsx(textareaInputCss, className)}
      {...props}
    />
  );
};
