import type { ButtonHTMLAttributes, ReactNode } from 'react';
import { Box, BoxProps } from './Box';
import { buttonCss } from './Button.css';

interface ButtonProps extends BoxProps {
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
}

export const Button = ({
  children,
  type = 'button',
  ...props
}: ButtonProps) => {
  return (
    <Box as="button" type={type} baseClassName={buttonCss} {...props}>
      {children}
    </Box>
  );
};
