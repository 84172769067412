import type { FunctionComponentFactory, ElementType } from 'react';
import { Bullet } from '../../components/Bullet';
import { cardCss, cardHolderCss, cardBackgroundCss } from './Card.css';

export const Card: FunctionComponentFactory<{ as: ElementType }> =
  function Card({ as: Component = 'div', children, ...props }) {
    return (
      <Component {...props} className={cardHolderCss}>
        <div className={cardBackgroundCss}></div>
        <div className={cardCss}>
          {children}

          <Bullet positions={['top', 'left']} />
          <Bullet positions={['top', 'right']} />
          <Bullet positions={['bottom', 'left']} />
          <Bullet positions={['bottom', 'right']} />
        </div>
      </Component>
    );
  };
