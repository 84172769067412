import { Box, BoxProps } from './Box';
import { gapCss, stackCss } from './Stack.css';

interface StackProps extends BoxProps {
  gap: keyof typeof gapCss;
}

export function Stack({ gap, children }: StackProps) {
  return <Box baseClassName={[stackCss, gapCss[gap]]}>{children}</Box>;
}
