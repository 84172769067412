// import { Link } from 'gatsby';
// import Img from 'gatsby-image';
// import { BoxWithBullets } from '../../components/BoxWithBullets';
import { graphql, navigate } from 'gatsby';
import {
  GatsbyImage,
  IGatsbyImageData,
  withArtDirection,
} from 'gatsby-plugin-image';
import { Card } from './Card';
import { Link } from '../../components/Link';
import {
  serviceListCss,
  serviceImageCss,
  cardInnerCss,
  serviceTitleCss,
  serviceLinkCss,
  serviceDescriptionCss,
} from './index.css';
import { phablet } from '../../theme/tokens/breakpoints';
import { phabletOnly } from '../../theme/utils.css';
import clsx from 'clsx';
import { Text } from '../../components/Text';

export interface IService {
  id: string;
  title: string;
  slug: string;
  description: string;
  image: {
    childImageSharp: {
      mobile: IGatsbyImageData;
      desktop: IGatsbyImageData;
    };
  };
}

export const ServiceList: React.FC<{
  services: Array<IService>;
  aboveTheFold?: boolean;
}> = function ServiceList({ services = [], aboveTheFold = false }) {
  return (
    <div className={serviceListCss}>
      {services.map((service) => {
        const artDirectedImage = withArtDirection(
          service.image.childImageSharp.desktop,
          [
            {
              image: service.image.childImageSharp.mobile,
              media: `(max-width: ${phablet}px)`,
            },
          ]
        );

        return (
          <Card
            key={service.id}
            onClick={() => {
              navigate(`/projecten/${service.slug}/`);
            }}
          >
            <GatsbyImage
              image={artDirectedImage}
              alt={service.title}
              loading={aboveTheFold ? 'eager' : 'lazy'}
              className={serviceImageCss}
            />
            <div className={cardInnerCss}>
              <h2 className={serviceTitleCss}>{service.title}</h2>
              <Text
                className={clsx(serviceDescriptionCss, phabletOnly)}
                variant="ghost"
              >
                {service.description}
              </Text>
              <Link
                to={`/projecten/${service.slug}/`}
                className={serviceLinkCss}
              >
                Bekijk →
              </Link>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export const fragment = graphql`
  fragment ServiceListFragment on Service {
    id
    title
    slug
    description
    image {
      childImageSharp {
        desktop: gatsbyImageData(
          layout: CONSTRAINED
          width: 300
          placeholder: BLURRED
        )
        mobile: gatsbyImageData(
          layout: CONSTRAINED
          height: 80
          placeholder: BLURRED
        )
      }
    }
  }
`;

// export const ServiceList = ({ images }) => {
//   return (
//     <div
//       sx={{
//         display: 'flex',
//         justifyContent: 'space-between',
//         maxWidth: '750px',
//         margin: '0 auto',
//         flexDirection: 'column',
//         '@media only screen and (min-width: 768px)': {
//           flexDirection: 'row',
//         },
//       }}
//     >
//       <Card
//         fluid={images[0].fluid}
//         title="Modern"
//         sx={{
//           boxSizing: 'border-box',
//           '@media only screen and (min-width: 768px)': {
//             width: '300px',
//           },
//         }}
//       >
//         <p>
//           Natoque purus praesent vitae sem parturient nullam varius tortor,
//           interdum primis aenean quam eros.
//         </p>
//       </Card>

//       <Card
//         fluid={images[0].fluid}
//         title="Landelijk"
//         sx={{
//           boxSizing: 'border-box',
//           '@media only screen and (min-width: 768px)': {
//             width: '300px',
//           },
//         }}
//       >
//         <p>
//           Natoque purus praesent vitae sem parturient nullam varius tortor,
//           interdum primis aenean quam eros.
//         </p>
//       </Card>
//     </div>
//   );
// };

// const Card = ({ children, fluid, title, ...props }) => {
//   return (
//     <div
//       {...props}
//       sx={{
//         position: 'relative',
//         cursor: 'pointer',
//         marginBottom: '15px',
//       }}
//     >
//       <div
//         sx={{
//           background: '#EFBC1D',
//           content: '""',
//           position: 'absolute',
//           top: 0,
//           left: 0,
//           right: 0,
//           bottom: 0,
//           display: 'none',
//           '@media only screen and (min-width: 768px)': {
//             display: 'block',
//           },
//         }}
//       ></div>
//       <BoxWithBullets
//         sx={{
//           backgroundColor: '#fff',
//           color: '#000',
//           display: 'flex',
//           flexDirection: 'row',
//           '@media only screen and (min-width: 768px)': {
//             flexDirection: 'column',
//             transition: 'transform 300ms ease-in-out',
//             ':hover': { transform: 'translate3d(-10px, -10px, 0)' },
//           },
//         }}
//       >
//         <Img
//           fluid={fluid}
//           alt={title}
//           sx={{
//             width: '50%',
//             ':after': {
//               bottom: 0,
//               content: '""',
//               left: 0,
//               position: 'absolute',
//               right: 0,
//               top: 0,
//               backgroundImage: `linear-gradient(90deg,
//           rgba(255, 255, 255, 0.0) 0%,
//           rgba(255, 255, 255, 0.0) 50%,
//           rgba(255, 255, 255, 0.7) 70%,
//           rgba(255, 255, 255, 0.9) 85%,
//           rgba(255, 255, 255, 1.0) 100%
//         )`,
//             },
//             '@media only screen and (min-width: 768px)': {
//               marginBottom: '-20px',
//               width: '100%',
//               ':after': {
//                 backgroundImage: `linear-gradient(
//           rgba(255, 255, 255, 0.0) 0%,
//           rgba(255, 255, 255, 0.0) 50%,
//           rgba(255, 255, 255, 0.2) 70%,
//           rgba(255, 255, 255, 0.7) 80%,
//           rgba(255, 255, 255, 0.9) 85%,
//           rgba(255, 255, 255, 1.0) 100%
//         )`,
//               },
//             },
//           }}
//         />
//         <div
//           sx={{
//             justifyContent: 'space-evenly',
//             display: 'flex',
//             flexDirection: 'column',
//             '@media only screen and (min-width: 768px)': {
//               padding: '0 25px 25px',
//               position: 'relative',
//             },
//           }}
//         >
//           <h2 sx={{ color: '#000', marginTop: 0, marginBottom: 0 }}>{title}</h2>
//           <div
//             sx={{
//               color: '#848484',
//               display: 'none',
//               '@media only screen and (min-width: 768px)': { display: 'block' },
//             }}
//           >
//             {children}
//           </div>
//           <Link
//             to="/"
//             sx={{
//               color: '#000',
//               fontWeight: 'bold',
//               textDecoration: 'none',
//             }}
//           >
//             Bekijk &rarr;
//           </Link>
//         </div>
//       </BoxWithBullets>
//     </div>
//   );
// };
