import { graphql, Link } from 'gatsby';
import { Layout } from '../layout';
import { Container } from '../components/Container';
import { Highlight } from '../components/Highlight';
import { Headline } from '../components/Headline';
import { ServiceList } from '../modules/ServiceList';
import { Text } from '../components/Text';
import {
  contactRowBricksCss,
  contactGridCss,
  listCss,
  listItemCss,
  contactFormCss,
  contactFormRowCss,
  contactFormRowStackCss,
  contactFormContainerCss,
  colEndCss,
  textAreaCss,
  buildingBackgroundCss,
  bricksCss,
  contactActionRowCss,
  warningCss,
} from './contacteer-ons.css';
import { Spacer } from '../components/Spacer';
import { Input, Label, Textarea } from '../components/Form';
import clsx from 'clsx';
import { Button } from '../components/Button';
import { Stack } from '../components/Stack';
import { desktopOnly, phabletOnly } from '../theme/utils.css';
import { useCallback, useState, useRef } from 'react';
import { Seo } from '../components/Seo';

export default function ContacteerOns({ data }) {
  const [isBusy, setBusy] = useState(false);
  const [error, setError] = useState(null);
  const formRef = useRef<HTMLFormElement>(null);
  const submitHandler = useCallback(
    (e) => {
      const formData = new FormData(e.target);
      const formBag = {};
      formData.forEach((value, key) => (formBag[key] = value));
      const json = JSON.stringify(formBag);
      setBusy(true);
      setError(null);

      fetch(e.target.action, {
        body: json,
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.ok) {
            formRef.current?.reset();
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setBusy(false);
        });

      e.preventDefault();
    },
    [setBusy]
  );

  return (
    <Layout>
      <Spacer y={10} />
      <Spacer y={10} className={desktopOnly} />
      <Container as="section" className={contactGridCss}>
        <div>
          <Highlight as={Headline}>Neem contact op</Highlight>
          <Spacer y={3} />

          <ul className={listCss}>
            <li className={listItemCss}>Een concrete vraag?</li>
            <li className={listItemCss}>Een vrijblijvende offerte?</li>
            <li className={listItemCss}>Een kennismakend gesprek?</li>
          </ul>
        </div>

        <div className={colEndCss}>
          <Text size="large" variant="ghost">
            Broekmansstraat 79A,
            <br />
            2221 Booischot
          </Text>
          <p>
            <Text
              as="a"
              size="large"
              variant="ghost"
              href="mailto:info@vanwoenselwoningbouw.be"
            >
              info@vanwoenselwoningbouw.be
            </Text>
          </p>
        </div>
        <div className={contactFormContainerCss}>
          <div className={contactRowBricksCss}>
            <svg
              className={bricksCss}
              viewBox="0 0 404 47"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="403.814"
                y="47"
                width="62.5571"
                height="20.7674"
                transform="rotate(-180 403.814 47)"
              />
              <rect
                x="335.72"
                y="47"
                width="62.5571"
                height="20.7674"
                transform="rotate(-180 335.72 47)"
              />
              <rect
                x="369.49"
                y="20.7675"
                width="62.5571"
                height="20.7674"
                transform="rotate(-180 369.49 20.7675)"
              />
              <rect
                x="267.627"
                y="47"
                width="62.5571"
                height="20.7674"
                transform="rotate(-180 267.627 47)"
              />
              <rect
                x="199.534"
                y="47"
                width="62.5571"
                height="20.7674"
                transform="rotate(-180 199.534 47)"
              />
              <rect
                x="233.304"
                y="20.7675"
                width="62.5571"
                height="20.7674"
                transform="rotate(-180 233.304 20.7675)"
              />
              <rect
                x="165.211"
                y="20.7675"
                width="62.5571"
                height="20.7674"
                transform="rotate(-180 165.211 20.7675)"
              />
              <rect
                x="131.441"
                y="47"
                width="62.5571"
                height="20.7674"
                transform="rotate(-180 131.441 47)"
              />
              <rect
                x="63.3477"
                y="47"
                width="62.5571"
                height="20.7674"
                transform="rotate(-180 63.3477 47)"
              />
              <rect
                x="97.1174"
                y="20.7675"
                width="62.5571"
                height="20.7674"
                transform="rotate(-180 97.1174 20.7675)"
              />
            </svg>
            <svg
              className={bricksCss}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 132 47"
            >
              <path d="M.066894 47h62.5329V26.2326H.066894zM34.377 20.7675h62.5329V.0001H34.377zM68.1333 47h62.5329V26.2326H68.1333z" />
            </svg>
          </div>
          <Spacer y={0.5} />

          <form
            className={contactFormCss}
            onSubmit={submitHandler}
            action="/api/contact"
            method="POST"
            ref={formRef}
          >
            <div className={contactFormRowCss}>
              <Text as={Label} variant="ghost" htmlFor="name">
                Naam:
              </Text>
              <Input id="name" name="name" required autoComplete="name" />
            </div>
            <div className={contactFormRowCss}>
              <Text as={Label} variant="ghost" htmlFor="email">
                E-mail:
              </Text>
              <Input id="email" name="email" required autoComplete="email" />
            </div>
            <div className={contactFormRowCss}>
              <Text as={Label} variant="ghost" htmlFor="phone">
                Telefoon:
              </Text>
              <Input
                id="phone"
                name="phone"
                required
                autoComplete="tel-national"
              />
            </div>
            <div className={clsx(contactFormRowCss, contactFormRowStackCss)}>
              <Text as={Label} variant="ghost" htmlFor="message">
                Bericht:
              </Text>
              <Textarea
                id="message"
                name="message"
                className={textAreaCss}
                required
                autoComplete="off"
              />
            </div>

            <div className={clsx(contactActionRowCss, contactFormRowCss)}>
              <Text type="submit" as={Button} size="large" disabled={isBusy}>
                Verstuur
              </Text>
              {error ? <Text className={warningCss}>{error}</Text> : null}
            </div>
          </form>
          <Spacer y={0.5} />
          <div className={contactRowBricksCss}>
            <svg
              className={bricksCss}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 131 47"
            >
              <path d="M.06665 0h62.5329v20.7674H.06665zM34.3767 26.2325h62.5329v20.7674H34.3767zM68.1335 0h62.5329v20.7674H68.1335z" />
            </svg>
            <svg
              className={bricksCss}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 233 47"
            >
              <path d="M34.0667 0h62.5329v20.7674H34.0667zM.310059 26.2325h62.5329v20.7674H.310059zM68.3767 26.2325h62.5329v20.7674H68.3767zM102.134 0h62.5329v20.7674H102.134zM170.2 0h62.5329v20.7674H170.2zM136.444 26.2325h62.5329v20.7674H136.444z" />
            </svg>
            <svg
              className={bricksCss}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 165 47"
            >
              <path d="M0 47h62.5329V26.2326H0zM68.0669 47h62.5329V26.2326H68.0669zM34.3101 20.7674H96.843V0H34.3101zM102.377 20.7674h62.5329V0H102.377z" />
            </svg>
          </div>
        </div>
      </Container>

      <Spacer y={5} className={desktopOnly} />
      <div className={buildingBackgroundCss}>
        <Container as="section">
          <Stack gap="large">
            <Spacer y={5} />
            <Spacer y={10} className={desktopOnly} />
            <Headline size="medium" level={2} align="center">
              Bekijk onze realisaties
            </Headline>
            <Spacer y={5} />
            <ServiceList services={data.services.nodes} aboveTheFold={true} />

            <Spacer y={5} />
            <Headline size="small" level={4} align="center">
              Benieuw naar onze aanpak?{' '}
              <Highlight as={Link} to="/over-ons">
                Lees meer →
              </Highlight>
            </Headline>
            <Spacer y={10} className={phabletOnly} />
          </Stack>
        </Container>
      </div>
    </Layout>
  );
}

export function Head() {
  return <Seo title="Contacteer ons" />;
}

export const query = graphql`
  query {
    services: allService {
      nodes {
        ...ServiceListFragment
      }
    }
  }
`;
